import { Route, Routes } from 'react-router-dom';

import { LayoutSite } from '../../ui/layout/site';
import { commonRoutes } from '../common-routes';

import { ScreenInfoNotFound } from '../_info/not-found';
import { ScreenSiteAttribution } from './attribution';
import { ScreenSiteHome } from './home';

export function ScreenSiteRouter() {
  return (
    <LayoutSite>
      <Routes>
        {/* common routes */}
        {commonRoutes.map((route) => route)}

        {/* app routes */}
        <Route path="/" element={<ScreenSiteHome />} />
        <Route path="/attribution" element={<ScreenSiteAttribution />} />
        <Route path="*" element={<ScreenInfoNotFound />} />
      </Routes>
    </LayoutSite>
  );
}
