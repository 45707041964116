import { Link as RouterLink } from 'react-router-dom';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import ButtonBase from '@mui/material/ButtonBase';

import logo from '../../../../assets/img/logo/text-black-54a.svg';

export function LayoutFooter() {
  return (
    <Box component="footer" sx={{ bgcolor: 'grey.100', py: 4 }}>
      <Container>
        <ButtonBase component="a" href="https://on.co.ke/" disableRipple>
          <img
            src={logo}
            alt="logo"
            style={{ maxWidth: '100%', width: '150px' }}
          />
        </ButtonBase>
        <Typography variant="caption" sx={{ mt: 2.5, display: 'block' }}>
          <Link to="/privacy-policy" component={RouterLink} target="_blank">
            Privacy Policy
          </Link>
          {' . '}
          <Link to="/terms-of-service" component={RouterLink} target="_blank">
            Terms of Service
          </Link>
        </Typography>
        <Divider sx={{ mt: 3, mb: 2 }} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="body2">
            © {new Date().getFullYear()} On.co.ke
          </Typography>
          <Stack spacing={1} direction="row">
            <IconButton
              component="a"
              href="https://twitter.com/OnDotCoDotKe"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterIcon fontSize="small" />
            </IconButton>
            <IconButton
              component="a"
              href="https://www.facebook.com/ONdotCOdotKE"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookIcon fontSize="small" />
            </IconButton>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}
