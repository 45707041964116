import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { ScreenInfoLayout } from './_layout';

export function ScreenInfoLoading({ title }: { title?: string | null }) {
  return (
    <ScreenInfoLayout>
      <Box sx={{ textAlign: 'center' }}>
        <CircularProgress />
        <Typography variant="body1" color="primary" sx={{ mt: 1 }}>
          {title}
        </Typography>
      </Box>
    </ScreenInfoLayout>
  );
}

ScreenInfoLoading.defaultProps = {
  title: 'Loading...',
};
