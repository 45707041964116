import { useNavigate } from 'react-router-dom';

import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import { ScreenInfoLayout } from './_layout';

export function ScreenInfoError() {
  const navigate = useNavigate();
  return (
    <ScreenInfoLayout>
      <Container maxWidth="xs">
        <Paper sx={{ p: 3, textAlign: 'center' }} variant="outlined">
          <Typography variant="h4" paragraph>
            🙈 Oops...
          </Typography>
          <Typography variant="body1" paragraph>
            Something went wrong
          </Typography>
          <Button
            variant="contained"
            disableElevation
            onClick={() => navigate('/')}
            fullWidth
          >
            Go Home
          </Button>
          <Button
            variant="outlined"
            disableElevation
            onClick={() => window.location.reload()}
            fullWidth
            sx={{ mt: 2 }}
          >
            Reload Page
          </Button>
        </Paper>
      </Container>
    </ScreenInfoLayout>
  );
}
