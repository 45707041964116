import React from 'react';

import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

import { AuthLayout } from '../../ui/layout/auth';

import { AuthLoginEffects } from '../../ui/auth/login/_effects';

import { AuthLoginGoogle } from '../../ui/auth/login/google';
import { AuthLoginApple } from '../../ui/auth/login/apple';
import { AuthLoginTwitter } from '../../ui/auth/login/twitter';

import { AuthLoginInput } from '../../ui/auth/login/input';

export function ScreenAuthLogin() {
  return (
    <>
      {/* effects */}
      <AuthLoginEffects />

      {/* components */}
      <AuthLayout>
        <Stack spacing={2.5}>
          <AuthLoginGoogle />
          <AuthLoginApple />
          <AuthLoginTwitter />
        </Stack>

        <Divider sx={{ my: 3 }}>OR</Divider>

        <AuthLoginInput />
      </AuthLayout>
    </>
  );
}
