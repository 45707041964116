import React from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import LinearProgress from '@mui/material/LinearProgress';

import { OfflineUpdateAvailable } from '../offline/update-available';

import { ThemeProvider } from './theme';
import { FirebaseEffects, FirebaseProvider } from './firebase';

import { ScreenInfoError } from '../../_screens/_info/error';

export function CommonProviders({ firebaseConfig, children }: any) {
  return (
    <React.Suspense fallback={<LinearProgress />}>
      <BrowserRouter>
        <ErrorBoundary FallbackComponent={ScreenInfoError}>
          <HelmetProvider>
            <FirebaseProvider config={firebaseConfig}>
              <ThemeProvider>
                {children}
                <FirebaseEffects />
                <OfflineUpdateAvailable />
              </ThemeProvider>
            </FirebaseProvider>
          </HelmetProvider>
        </ErrorBoundary>
      </BrowserRouter>
    </React.Suspense>
  );
}
