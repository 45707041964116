import React from 'react';

import { getAuth, sendSignInLinkToEmail } from 'firebase/auth';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

import { logError } from '../../../_lib/utils/log-error';

export function AuthLoginInput() {
  const auth = getAuth();

  const [input, setInput] = React.useState('');
  const [inputError, setInputError] = React.useState('');

  const [emailSentAlertShow, setEmailSentAlertShow] = React.useState(false);
  const [emailErrorAlertShow, setEmailErrorAlertShow] = React.useState(false);

  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: `${window.location.origin}/login`,
    // This must be true.
    handleCodeInApp: true,
    iOS: {
      bundleId: 'ke.co.on',
    },
    android: {
      packageName: 'ke.co.on',
      installApp: true,
      minimumVersion: '12',
    },
    dynamicLinkDomain: 'oncoke.page.link',
  };

  const handleSubmit = () => {
    setEmailErrorAlertShow(false);
    setInputError('');

    if (input.length === 0) {
      setInputError('Please enter an email address');
      return;
    }

    sendSignInLinkToEmail(auth, input, actionCodeSettings)
      .then(() => {
        window.localStorage.setItem('emailForSignIn', input);
        setEmailSentAlertShow(true);
      })
      .catch((error) => {
        logError(error);
        setEmailErrorAlertShow(true);
      });
  };

  // ------------------------------------------------------------------------------------

  return (
    <Box sx={{ textAlign: 'left' }}>
      <TextField
        variant="outlined"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        type="email"
        autoFocus
        onChange={(e) => setInput(e.target.value)}
        error={!!inputError}
        helperText={inputError}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        sx={{ my: 2, textTransform: 'none' }}
        disableElevation
        onClick={() => handleSubmit()}
      >
        Sign in with Email
      </Button>

      {!(emailSentAlertShow || emailErrorAlertShow) && (
        <Alert severity="info" icon={<AutoAwesomeIcon />}>
          We will send you a magic code or link for a password-free sign in
          experience
        </Alert>
      )}

      {/* success */}
      {emailSentAlertShow && (
        <Alert severity="success">Check your inbox for a sign in link.</Alert>
      )}

      {/* error */}
      {emailErrorAlertShow && (
        <Alert severity="error">Something went wrong. Please try again.</Alert>
      )}
    </Box>
  );
}
