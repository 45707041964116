import {
  DialogTitle as MuiDialogTitle,
  IconButton,
  SxProps,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export interface DialogTitleProps {
  children?: React.ReactNode;
  sx?: SxProps;
  onClose: () => void;
}

export function DialogTitle(props: DialogTitleProps) {
  const { children, onClose, sx, ...other } = props;

  return (
    <MuiDialogTitle sx={{ m: 0, p: 2, ...sx }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}

DialogTitle.defaultProps = {
  children: null,
  sx: undefined,
};
