import { getAuth, signOut } from 'firebase/auth';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

import { ScreenInfoLayout } from './_layout';

export function ScreenInfoForbidden() {
  const handleSignOut = () => {
    signOut(getAuth());
  };

  return (
    <ScreenInfoLayout>
      <Container maxWidth="xs">
        <Paper sx={{ p: 3 }} variant="outlined">
          <Alert severity="info">
            admin.on.co.ke is used for Workspace accounts only. Regular accounts
            cannot be used to sign in to admin.on.co.ke
          </Alert>
          <Button
            variant="contained"
            disableElevation
            sx={{ mt: 3 }}
            fullWidth
            onClick={handleSignOut}
          >
            Sign Out
          </Button>
        </Paper>
      </Container>
    </ScreenInfoLayout>
  );
}
