import { Link as RouterLink } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import LogoLong from '../../../assets/img/logo/text.png';

import { LayoutBase } from './base';

export function AuthLayout({ children }: any) {
  return (
    <LayoutBase>
      <Container component="main" maxWidth="xs">
        <Box sx={{ textAlign: 'center', pt: 3, pb: 12 }}>
          {/* logo */}
          <Box sx={{ mx: 'auto', my: 4, px: 2 }}>
            <img
              src={LogoLong}
              alt="Logo"
              style={{ maxWidth: '100%', maxHeight: '45px' }}
            />
          </Box>
          <Typography
            component="h1"
            variant="h4"
            sx={{ mt: 4, mb: 2, fontWeight: 600 }}
          >
            Sign in to On.co.ke
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            We suggest using an{' '}
            <strong>email address with two-factor authentication</strong>.
          </Typography>

          {children}

          {/* Back to home link */}
          <Box sx={{ mt: 5, textAlign: 'left' }}>
            <Button
              to="/"
              component={RouterLink}
              startIcon={<KeyboardBackspaceIcon />}
            >
              Back to Home
            </Button>
          </Box>
        </Box>
      </Container>
    </LayoutBase>
  );
}
