import React from 'react';

import { useAuth } from 'reactfire';

import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';

import { logError } from '../../../_lib/utils/log-error';

export function AuthLoginEffects() {
  const auth = useAuth();

  React.useEffect(() => {
    // confirm the link is a sign-in with email link.
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let emailForSignIn = window.localStorage.getItem('emailForSignIn');
      while (!emailForSignIn) {
        // eslint-disable-next-line no-alert
        emailForSignIn = window.prompt(
          'Please provide your email for confirmation'
        );
      }

      signInWithEmailLink(auth, emailForSignIn, window.location.href)
        .then((result) => {
          window.localStorage.removeItem('emailForSignIn');
          return result;
        })
        .catch((error) => {
          logError(error);
        });
    }
  }, [auth]);

  return null;
}
