import { getAuth, signInWithPopup, OAuthProvider } from 'firebase/auth';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Twittercon from '@mui/icons-material/Twitter';

export function AuthLoginTwitter() {
  const auth = getAuth();
  const provider = new OAuthProvider('twitter.com');

  const handleClick = () => {
    signInWithPopup(auth, provider);
  };

  return (
    <Box color="#1DA1F2">
      <Button
        variant="outlined"
        color="inherit"
        fullWidth
        onClick={handleClick}
        startIcon={<Twittercon />}
        sx={{
          border: 2,
          textTransform: 'none',
        }}
      >
        Sign in with Twitter
      </Button>
    </Box>
  );
}
