import { CommonProviders } from '@on.co.ke/core/src/_lib/providers/common';

import { ScreenSiteRouter } from '@on.co.ke/core/src/_screens/site/_router';

function App() {
  return (
    <CommonProviders>
      <ScreenSiteRouter />
    </CommonProviders>
  );
}

export default App;
