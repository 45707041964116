import Box from '@mui/material/Box';

import '../../../assets/css/_full-height.css';

export function ScreenInfoLayout({ children }: any) {
  return (
    <Box
      sx={{
        height: '100%',
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {children}
    </Box>
  );
}
