import { Helmet } from 'react-helmet-async';
import { ScreenInfoLoading } from './loading';

export function ScreenInfoRedirect({ to }: { to?: string }) {
  return (
    <>
      <Helmet>
        {to && <meta httpEquiv="refresh" content={`0; url=${to}`} />}
      </Helmet>
      <ScreenInfoLoading title={null} />
    </>
  );
}

ScreenInfoRedirect.defaultProps = {
  to: undefined,
};
