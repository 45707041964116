import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export function ScreenSiteHome() {
  return (
    <Box
      sx={{
        flexGrow: 1,
        p: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'primary.main',
        rowGap: 3,
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          columnGap: 1,
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h1"
          sx={{ color: 'primary.main', fontWeight: 700 }}
        >
          Coming{' '}
        </Typography>
        <Typography variant="h1" sx={{ fontWeight: 300 }}>
          Soon
        </Typography>
      </Box>
      <Typography sx={{ textAlign: 'center' }}>
        &ldquo;Kata simu, niko kwa site&rdquo; - Kenyan Proverb
      </Typography>
    </Box>
  );
}
