import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

export function ScreenSiteAttribution() {
  return (
    <Container sx={{ py: 7 }} maxWidth="sm">
      <Typography variant="h4" sx={{ mb: 2 }}>
        Attribution
      </Typography>
      <ul>
        <li>
          <Typography>
            <a href="https://www.freepik.com/vectors/kenya">
              Kenya vector created by freepik - www.freepik.com
            </a>
          </Typography>
        </li>
        <li>
          <Typography>
            <a href="https://www.freepik.com/vectors/kenya-flag">
              Kenya flag vector created by freepik - www.freepik.com
            </a>
          </Typography>
        </li>
        <li>
          <Typography>
            <a href="https://www.freepik.com/vectors/kenya">
              Kenya vector created by freepik - www.freepik.com
            </a>
          </Typography>
        </li>
      </ul>
    </Container>
  );
}
