import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import GoogleIcon from '../../../../assets/img/icons/google.svg';

export function AuthLoginGoogle() {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();

  const handleClick = () => {
    signInWithPopup(auth, provider);
  };

  return (
    <Box color="#4285F4">
      <Button
        variant="outlined"
        color="inherit"
        fullWidth
        onClick={handleClick}
        startIcon={
          <img src={GoogleIcon} alt="google icon" style={{ height: '20px' }} />
        }
        sx={{
          textTransform: 'none',
          border: 2,
        }}
      >
        Sign in with Google
      </Button>
    </Box>
  );
}
