import { Navigate, useLocation } from 'react-router-dom';
import { useSigninCheck } from 'reactfire';

import { getAuth, signOut } from 'firebase/auth';

import { ScreenInfoLoading } from '../../_screens/_info/loading';
import { ScreenInfoForbidden } from '../../_screens/_info/forbidden';

// waitlist route
export function WaitlistRoute({ children }: any) {
  const location = useLocation();
  const { status, data: signInCheck } = useSigninCheck({
    requiredClaims: { activated_web: 'true' },
  });

  if (status === 'loading') {
    return <ScreenInfoLoading />;
  }

  if (signInCheck.hasRequiredClaims && location.pathname === '/waitlist') {
    return <Navigate to="/" state={{ from: location }} />;
  }

  if (signInCheck.hasRequiredClaims || location.pathname === '/waitlist') {
    return children;
  }

  return <Navigate to="/waitlist" state={{ from: location }} />;
}

// should be logged in
export function PrivateRoute({ children }: any) {
  const location = useLocation();
  const { status, data: signInCheck } = useSigninCheck();

  if (status === 'loading') {
    return <ScreenInfoLoading />;
  }

  if (signInCheck.signedIn) {
    // redirect to the original path if signed in
    const { state } = location as any;
    if (state && state.from) {
      if (
        state.from.pathname === '/login' &&
        state.from.state &&
        state.from.state.from
      ) {
        return <Navigate to={state.from.state.from.pathname} />;
      }
    }

    // allow logout
    if (location.pathname === '/logout') return children;

    // otherwise return wrapped around waitlist check
    return <WaitlistRoute>{children}</WaitlistRoute>;
  }

  return <Navigate to="/login" state={{ from: location }} />;
}

// should not be logged in
export function ReversePrivateRoute({ children }: any) {
  const location = useLocation();
  const { status, data: signInCheck } = useSigninCheck();

  if (status === 'loading') {
    return <ScreenInfoLoading />;
  }

  if (signInCheck.signedIn) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return children;
}

// logout route
export function LogoutRoute() {
  signOut(getAuth());
  return <ScreenInfoLoading />;
}

// staff route
export function StaffRoute({ children }: any) {
  const location = useLocation();
  const { status, data: signInCheck } = useSigninCheck({
    requiredClaims: { staff: 'true' },
  });

  if (status === 'loading') {
    return <ScreenInfoLoading />;
  }

  if (signInCheck.hasRequiredClaims) {
    return children;
  }

  if (!signInCheck.signedIn) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <ScreenInfoForbidden />;
}
