import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { ScreenInfoLayout } from './_layout';

export function ScreenInfoNotFound() {
  const navigate = useNavigate();
  return (
    <ScreenInfoLayout>
      <Container maxWidth="xs">
        <Typography variant="h2" paragraph>
          404
        </Typography>
        <Typography variant="h4" paragraph>
          Page Not Found
        </Typography>
        <Typography variant="body1" paragraph>
          The page you are looking for cannot be found.
        </Typography>
        <Button
          variant="contained"
          disableElevation
          onClick={() => navigate('/')}
        >
          Go Home
        </Button>
      </Container>
    </ScreenInfoLayout>
  );
}
