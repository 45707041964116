import { Link as RouterLink } from 'react-router-dom';

import { useUser } from 'reactfire';
import { getAuth, signOut } from 'firebase/auth';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import LogoutIcon from '@mui/icons-material/Logout';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';

import { DialogTitle } from '../../../../dialog-title';

import Logo from '../../../../../../assets/img/logo/text.svg';

export function LayoutNavbarActionsAvatarDialog({ open, setOpen }: any) {
  const auth = getAuth();
  const { status, data: user } = useUser();

  const handleSignOut = () => {
    signOut(auth).then(() => {
      setOpen(false);
    });
  };

  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      <DialogTitle onClose={() => setOpen(false)} sx={{ textAlign: 'center' }}>
        <img alt="logo" src={Logo} style={{ height: 20 }} />
      </DialogTitle>
      <Box sx={{ p: 2, pt: 0, textAlign: 'center' }}>
        {status === 'loading' && <CircularProgress />}
        {status !== 'loading' && user && (
          <>
            <Avatar
              sx={{ mx: 'auto', mb: 1 }}
              alt={user.displayName || 'avatar'}
              src={user.photoURL || undefined}
            />
            <Typography variant="h5">{user.displayName}</Typography>
            <Typography variant="body2" sx={{ color: 'grey.700' }}>
              {user.email}
            </Typography>
          </>
        )}
        {/* TODO: Manage account */}
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <Button
          fullWidth
          variant="outlined"
          startIcon={<LogoutIcon />}
          sx={{ textTransform: 'none' }}
          onClick={handleSignOut}
        >
          Sign Out
        </Button>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <Typography variant="body2" sx={{ textAlign: 'center' }}>
          <Link component={RouterLink} to="/terms-of-service" target="_blank">
            Terms of Service
          </Link>
          {' . '}
          <Link component={RouterLink} to="/privacy-policy" target="_blank">
            Privacy Policy
          </Link>
        </Typography>
      </Box>
    </Dialog>
  );
}
