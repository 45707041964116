import { useNavigate } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import ButtonBase from '@mui/material/ButtonBase';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';

import { LayoutNavbarActionsAvatar } from './actions/avatar';

import Logo from '../../../../assets/img/logo/text.svg';

export function LayoutNavbarAppbar() {
  const navigate = useNavigate();

  return (
    <AppBar
      position="static"
      elevation={0}
      color="transparent"
      sx={{
        borderBottom: '1px solid',
        borderColor: 'grey.400',
      }}
    >
      <Toolbar variant="dense">
        {/* logo */}
        <Box sx={{ mr: 'auto' }}>
          <ButtonBase
            onClick={() => navigate('/')}
            component="div"
            disableRipple
          >
            <img
              alt="logo"
              src={Logo}
              style={{
                height: '20px',
              }}
            />
          </ButtonBase>
        </Box>

        {/* actions */}
        <Box>
          <LayoutNavbarActionsAvatar />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
