import { ErrorBoundary } from 'react-error-boundary';

import Box from '@mui/material/Box';

import { LayoutBase } from './base';
import { LayoutFooter } from './footer';
import { LayoutNavbarAppbar } from './navbar/appbar';

import { ScreenInfoError } from '../../_screens/_info/error';

import '../../../assets/css/_full-height.css';

export function LayoutWeb({ children }: any) {
  return (
    <LayoutBase>
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}>
        {/* appbar */}
        <LayoutNavbarAppbar />

        {/* main */}
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <ErrorBoundary FallbackComponent={ScreenInfoError}>
            {children}
          </ErrorBoundary>
        </Box>

        {/* footer */}
        <LayoutFooter />
      </Box>
    </LayoutBase>
  );
}
