import React from 'react';

import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';

import CloseIcon from '@mui/icons-material/Close';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

export function OfflineUpdateAvailable() {
  const [showUpdateAvailable, setShowUpdateAvailable] = React.useState(false);
  const [waitingWorker, setWaitingWorker] =
    React.useState<ServiceWorker | null>(null);

  // --------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------
  // handlers
  // --------------------------------------------------------------------------------------------------------

  const handleUpdateAvailable = () => {
    setShowUpdateAvailable(true);
  };

  const handleUpdate = () => {
    if (waitingWorker) {
      waitingWorker.postMessage({ type: 'SKIP_WAITING' });
      window.location.reload();
    }
  };

  // --------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------
  // effects
  // --------------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => {
          registration.waiting?.postMessage({ type: 'SKIP_WAITING' });
        });
      });
    }

    serviceWorkerRegistration.register({
      onUpdate: (registration) => {
        setWaitingWorker(registration.waiting);
        handleUpdateAvailable();
      },
    });
  }, []);

  // --------------------------------------------------------------------------------------------------------

  return (
    <Snackbar
      open={showUpdateAvailable}
      color="warning"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Alert
        severity="info"
        action={
          <Box sx={{ display: 'flex', alignSelf: 'center' }}>
            <Box>
              <Button
                color="info"
                size="small"
                onClick={handleUpdate}
                variant="contained"
                disableElevation
                sx={{ whiteSpace: 'nowrap' }}
              >
                Update Now
              </Button>
            </Box>
            <Box>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setShowUpdateAvailable(false)}
                sx={{ ml: 0.7 }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        }
      >
        There is a new update available
      </Alert>
    </Snackbar>
  );
}
