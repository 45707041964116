import { Route } from 'react-router-dom';
import { ReversePrivateRoute } from '../_lib/utils/routes';
import { ScreenAuthLogin } from './auth/login';
import { ScreenInfoRedirect } from './_info/redirect';

export const commonRoutes = [
  <Route
    key="terms"
    path="/terms-of-service"
    element={<ScreenInfoRedirect to="https://bit.ly/3qWqnKG" />}
  />,
  <Route
    key="privacy"
    path="/privacy-policy"
    element={<ScreenInfoRedirect to="https://bit.ly/3tCliZA" />}
  />,
  <Route
    key="login"
    path="/login"
    element={
      <ReversePrivateRoute>
        <ScreenAuthLogin />
      </ReversePrivateRoute>
    }
  />,
];
