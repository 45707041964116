import CssBaseline from '@mui/material/CssBaseline';
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
  StyledEngineProvider,
} from '@mui/material/styles';

import '@fontsource/lexend/latin.css';

const lexendFont = ['Lexend', 'sans-serif'].join(',');
const theme = createTheme({
  palette: {
    primary: {
      main: '#023047',
    },
    secondary: {
      main: '#219EBC',
    },
  },
  typography: {
    fontFamily: lexendFont,
    fontWeightRegular: 300,
  },
});

export function ThemeProvider({ children }: any) {
  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
}
