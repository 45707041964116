import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSigninCheck, useUser } from 'reactfire';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';

import { LayoutNavbarActionsAvatarDialog } from './dialog';

export function LayoutNavbarActionsAvatar() {
  const { status: statusSignInCheck, data: signInCheckResult } =
    useSigninCheck();
  const { status, data: user } = useUser();

  const [openAvatarDialog, setOpenAvatarDialog] = React.useState(false);

  // ------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------
  // handlers
  // ------------------------------------------------------------------------------------------------

  const handleAvatarClick = () => {
    setOpenAvatarDialog(true);
  };

  // ------------------------------------------------------------------------------------------------

  if (status === 'loading' || statusSignInCheck === 'loading')
    return (
      <CircularProgress style={{ height: 20, width: 20 }} color="inherit" />
    );

  return (
    <>
      {/* Dialog */}
      <LayoutNavbarActionsAvatarDialog
        open={openAvatarDialog}
        setOpen={setOpenAvatarDialog}
      />

      {/* Buttons */}
      {signInCheckResult.signedIn !== true && (
        <Button
          color="primary"
          component={RouterLink}
          to="/login"
          size="small"
          variant="contained"
          disableElevation
        >
          Login
        </Button>
      )}
      {signInCheckResult.signedIn === true && user && (
        <IconButton onClick={handleAvatarClick}>
          <Avatar
            alt={user.displayName || 'avatar'}
            src={user.photoURL || undefined}
            sx={{ height: 24, width: 24 }}
          />
        </IconButton>
      )}
    </>
  );
}
