import React from 'react';
import { useLocation } from 'react-router-dom';

import {
  FirebaseAppProvider,
  FirestoreProvider,
  useFirebaseApp,
  AnalyticsProvider,
  AuthProvider,
  useInitPerformance,
  AppCheckProvider,
} from 'reactfire';

import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

const firebaseConfig = {
  apiKey: 'AIzaSyDi-ajvJd32GuqnvvXIZTZkXkkl-dNhc-8',
  authDomain: 'accounts.on.co.ke',
  projectId: 'oncoke-49377',
  storageBucket: 'oncoke-49377.appspot.com',
  messagingSenderId: '181955447600',
  appId: '1:181955447600:web:26979ec2ccd7ae19f616f5',
  measurementId: 'G-2GXWJVXZVH',
};

const APP_CHECK_TOKEN = '6LcJIKQeAAAAALq1Krjet0gibpB2A_6Bbp4TaX8p';

function FirebaseServicesProviders({ children }: any) {
  const app = useFirebaseApp();

  const auth = getAuth(app);
  const firestoreInstance = getFirestore(app);
  const analytics = getAnalytics(app);

  useInitPerformance(async (firebaseApp) => {
    const { getPerformance } = await import('firebase/performance');
    return getPerformance(firebaseApp);
  });

  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(APP_CHECK_TOKEN),
    isTokenAutoRefreshEnabled: true,
  });

  return (
    <AppCheckProvider sdk={appCheck}>
      <AuthProvider sdk={auth}>
        <FirestoreProvider sdk={firestoreInstance}>
          <AnalyticsProvider sdk={analytics}>{children}</AnalyticsProvider>
        </FirestoreProvider>
      </AuthProvider>
    </AppCheckProvider>
  );
}

export function FirebaseProvider({ config, children }: any) {
  // Point Firestore + Auth to localhost if localhost
  React.useEffect(() => {
    if (window.location.hostname === 'localhost') {
      const db = getFirestore();
      connectFirestoreEmulator(db, 'localhost', 8080);
      const auth = getAuth();
      connectAuthEmulator(auth, 'http://localhost:9099', {
        disableWarnings: true,
      });
    }
  }, []);

  return (
    <FirebaseAppProvider firebaseConfig={{ ...firebaseConfig, ...config }}>
      <FirebaseServicesProviders>{children}</FirebaseServicesProviders>
    </FirebaseAppProvider>
  );
}

// FirebaseEffects
export function FirebaseEffects() {
  const location = useLocation();

  const app = useFirebaseApp();
  const analytics = getAnalytics(app);

  // analytics
  React.useEffect(() => {
    const pagePath = location.pathname + location.search;
    logEvent(analytics, 'page_view', { pagePath });
  }, [location, analytics]);

  return null;
}
